<template>
  <div id="line" :style="{ width: '570px', height: '350px' }"></div>
</template>

<script>
export default {
  data() {
    return {
      // 折线数据
      lineData: [4000, 8000, 15000, 12000, 30000, 16000, 20000, 50000],
    };
  },
  created() {
    var that = this;
    setTimeout(function () {
      that.drawLine();
    }, 300);
  },
  methods: {
    // 年龄/收入折线图
    drawLine() {
      // 绘制图表
      let myChart = this.$echarts.init(document.getElementById("line"));
      let option = {
        title: {
          text: "年龄/收入折线图",
        },
        // 网格属性
        grid: {
          bottom: 30,
        },
        xAxis: {
          show: true,
          type: "category",
          name: "年龄",
          boundaryGap: false,
          data: ["20以下", 25, 30, 35, 40, 45, 50, "55以上"],
          // X轴网格分割线
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        yAxis: {
          show: true,
          name: "收入",
          // Y轴分割线
          axisLine: {
            show: true,
            lineStyle: {
              type: "solid",
            },
          },
          // Y轴网格分割线
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        series: [
          {
            data: this.lineData,
            type: "line",
            // 图形上的文本标签
            label: {
              show: true,
              position: "top",
            },
            // 折线拐点颜色
            itemStyle: {
              color: "#FFB5B5",
            },
            // 折线线条颜色
            lineStyle: {
              color: "#FFB5B5",
            },
            // 设置阴影部分填充颜色
            areaStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(255, 139, 139)",
                },
                {
                  offset: 1,
                  color: "rgba(255, 139, 139,0.01)",
                },
              ]),
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>

<style>
</style>