<template>
  <div :id="pieId" :style="{ width: '380px', height: '240px' }"></div>
</template>

<script>
export default {
  name: "pieMap",
  props: {
    pieId: {
      type: String,
      require: true,
    },
    pieTitle: {
      type: String,
      require: true,
    },
    dateItem: {
      type: Array,
      require: true,
    },
  },
  created() {
    var that = this;
    setTimeout(function () {
      that.drawPie();
    }, 60);
  },
  methods: {
    // 饼图
    drawPie() {
      let myChart = this.$echarts.init(document.getElementById(this.pieId));
      let option = {
        // 标题
        title: {
          text: this.pieTitle,
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} : {d}%",
        },
        series: [
          {
            type: "pie",
            radius: "70%",
            center: ["50%", "60%"],
            label: {
              formatter: "{b} \n {d}%",
              show: true,
            },
            data: this.dateItem,
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
  watch: {
    dateItem: {
      handler() {
        this.drawPie();
      },
      deep: true,
    },
  },
};
</script>

<style>
</style>