<template>
  <div id="userInfo">
    <div v-if="dataNull">
      <limits></limits>
    </div>
    <div v-else>
      <div class="userAdd">
        <div class="text">
          <div class="text_n">{{ addUser }}</div>
          <div>较{{ nowDate }}新增用户</div>
        </div>
        <div class="text da">
          <div class="text_n">{{ addUserNot }}</div>
          <div>较{{ nowDate }}新增用户但不填写资料</div>
        </div>
        <div class="text">
          <div class="text_n">{{ addMember }}</div>
          <div>较{{ nowDate }}新增会员</div>
        </div>
      </div>
      <div class="show">用户数据展示</div>
      <div class="echart">
        <bar-map
          class="map"
          :info="zu"
          barId="barAn"
          barTitle="祖籍所在地占比"
        ></bar-map>
        <line-map class="map"></line-map>
        <bar-map
          class="map"
          :info="now"
          barId="barCity"
          barTitle="现居住地占比"
        ></bar-map>
      </div>
      <div class="pie">
        <pie-map
          class="pie_"
          pieId="pie1"
          :dateItem="genderList"
          pieTitle="男女比例"
        ></pie-map>
        <pie-map
          class="pie_"
          pieId="pie2"
          :dateItem="educationList"
          pieTitle="学历占比"
        ></pie-map>
        <pie-map
          class="pie_"
          pieId="pie3"
          :dateItem="heightList"
          pieTitle="身高占比/cm"
        ></pie-map>
        <pie-map
          class="pie_"
          pieId="pie4"
          :dateItem="ageList"
          pieTitle="年龄占比"
        ></pie-map>
      </div>
    </div>
  </div>
</template>

<script>
import barMap from "../echarts/barMap.vue";
import LineMap from "../echarts/lineMap.vue";
import PieMap from "../echarts/pieMap.vue";

import { request } from "../../API/request";
import Limits from "../common/limits.vue";

export default {
  components: { barMap, LineMap, PieMap, Limits },
  data() {
    return {
      // 祖籍所在地地址
      zu: {
        city: [],
        info: [],
      },
      // 现居住地数据
      now: {
        city: [],
        info: [],
      },

      nowDate: "", // 区别是哪个表
      addUser: 0, // 新增用户
      addUserNot: 0, // 新增用户不填资料
      addMember: 0, // 新增会员
      ageList: [], // 年龄占比
      educationList: [], // 学历占比
      genderList: [], // 性别占比
      heightList: [], // 身高占比
      dataNull: true, // 数据是否为空
    };
  },
  mounted() {
    this.getEchartInfo();
  },
  methods: {
    getEchartInfo() {
      console.log(123131);
      this.$bus.$on("dataDate", (e) => {
        let name = this.$route.name;
        if (name === "daily") {
          this.nowDate = "昨日";
          request({
            url: "/count/day",
            method: "GET",
            params: {
              startDay: e,
            },
          }).then((res) => {
            if (res.code === 200) {
              this.dataNull = false;
              this.addUser = res.data.customer.count;
              this.addMember = res.data.vip.count;
              let homelist = this.forHometownList(res.data.hometown);
              this.zu = {
                city: homelist.hometownList,
                info: homelist.countList,
                amount: res.data.customer.count,
              };
              let locaList = this.forLocationList(res.data.location);

              this.now = {
                city: locaList.locationList,
                info: locaList.countList,
                amount: res.data.customer.count,
              };
              this.ageList = res.data.ageCount;
              this.educationList = res.data.education;
              this.genderList = res.data.gender;
              this.heightList = res.data.height;
            } else if (res.code === 1004) {
              this.dataNull = true;
            }
          });
        } else if (name === "weekly") {
          this.nowDate = "上周";
          request({
            url: "/count/week",
            method: "GET",
            params: e,
          }).then((res) => {
            if (res.code === 200) {
              this.dataNull = false;
              this.addUser = res.data.customer.count;
              this.addMember = res.data.vip.count;
              let homelist = this.forHometownList(res.data.hometown);
              this.zu = {
                city: homelist.hometownList,
                info: homelist.countList,
                amount: res.data.customer.count,
              };
              let locaList = this.forLocationList(res.data.location);
              this.now = {
                city: locaList.locationList,
                info: locaList.countList,
                amount: res.data.customer.count,
              };
              this.ageList = res.data.ageCount;
              this.educationList = res.data.education;
              this.genderList = res.data.gender;
              this.heightList = res.data.height;
            } else if (res.code === 1004) {
              this.dataNull = true;
            } else {
              this.$message.error(res.message);
            }
          });
        } else if (name === "monthly") {
          this.nowDate = "上月";
          request({
            url: "/count/month",
            method: "GET",
            params: {
              startDay: e,
            },
          }).then((res) => {
            if (res.code === 200) {
              this.dataNull = false;
              this.addUser = res.data.customer.count;
              this.addMember = res.data.vip.count;
              let homelist = this.forHometownList(res.data.hometown);
              this.zu = {
                city: homelist.hometownList,
                info: homelist.countList,
                amount: res.data.customer.count,
              };
              let locaList = this.forLocationList(res.data.location);
              this.now = {
                city: locaList.locationList,
                info: locaList.countList,
                amount: res.data.customer.count,
              };
              this.ageList = res.data.ageCount;
              this.educationList = res.data.education;
              this.genderList = res.data.gender;
              this.heightList = res.data.height;
            } else if (res.code === 1004) {
              this.dataNull = true;
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    // 操作提取所在地数据
    forHometownList(list) {
      let hometownList = [];
      let countList = [];
      for (let i = 0; i < list.length; i++) {
        hometownList.unshift(list[i].hometown);
        countList.unshift(list[i].count);
      }
      return {
        hometownList,
        countList,
      };
    },
    // 操作提取居住地数据
    forLocationList(list) {
      let locationList = [];
      let countList = [];
      for (let i = 0; i < list.length; i++) {
        locationList.unshift(list[i].location);
        countList.unshift(list[i].count);
      }
      return {
        locationList,
        countList,
      };
    },
  },
  destroyed() {
    this.$bus.$off("dataDate");
  },
};
</script>

<style lang="less" scoped>
#userInfo {
  margin-top: 12px;
  font-size: var(--mainSize);
  .userAdd {
    border: 1px solid var(--pink);
    padding: 12px;
    display: flex;
    justify-content: space-around;
    .text {
      flex: 1;
      text-align: center;
      .text_n {
        font-size: var(--maxSize);
        font-weight: bold;
        color: var(--pink);
      }
      &:nth-child(2) {
        border-left: 2px solid var(--pink);
        border-right: 2px solid var(--pink);
      }
    }
  }
  .show {
    margin: 12px 0;
    color: var(--pink);
    position: relative;
    padding-bottom: 12px;
    font-size: var(--subSize);
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;
      width: 100%;
      border-bottom: 1px solid #dbdbdb;
      box-sizing: border-box;
      z-index: 1;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;
      width: 3rem;
      border-bottom: 2px solid #ef420e;
      box-sizing: border-box;
      z-index: 2;
    }
  }
  .echart {
    display: flex;
    justify-content: space-between;
    .map {
      border: 1px solid var(--pink);
      padding: 12px;
    }
  }
  .pie {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    .pie_ {
      border: 1px solid var(--pink);
      padding: 12px;
    }
  }
}
</style>