<template>
  <div id="limits">
    <el-image :src="img" lazy style="width: 606.5px; height: 436px" />
    <div class="wu">{{ tips }}</div>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      require: true,
      default: require("../../assets/img/bg.png"),
    },
    tips: {
      type: String,
      require: true,
      default: "暂无数据",
    },
  },
};
</script>

<style lang="less" scoped>
#limits {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 10%;
  justify-content: center;
  align-items: center;
  font-size: var(--bigSize);
  .wu {
    position: absolute;
    top: 60%;
    color: var(--info);
  }
}
</style>