<template>
  <div :id="barId" :style="{ width: '500px', height: '350px' }"></div>
</template>

<script>
export default {
  props: {
    // 图形标题
    barTitle: {
      type: String,
      required: true,
    },
    // ID
    barId: {
      type: String,
      required: true,
    },
    // // 数据
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {
    var that = this;
    setTimeout(function () {
      that.drawBar();
    }, 60);
  },
  methods: {
    // 柱形图
    drawBar() {
      let amount = this.info.amount;
      let myChart = this.$echarts.init(document.getElementById(this.barId));
      let option = {
        title: {
          text: this.barTitle,
        },
        // 网格属性
        grid: {
          top: 30,
          left: "15%",
          bottom: 0,
        },
        xAxis: {
          show: false,
        },
        yAxis: {
          type: "category",
          data: this.info.city,
          // Y轴分割线
          axisLine: {
            show: false,
          },
          // Y轴网格分割线
          splitLine: {
            show: false,
          },
          // 坐标轴刻度相关
          axisTick: {
            show: false,
          },
        },
        // 鼠标移入显示详情数据
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        series: [
          {
            data: this.info.info,
            type: "bar",
            label: {
              show: true,
              formatter: function (params) {
                return ((params.value / amount) * 100).toFixed(2) + "%";
              },
            },
            itemStyle: {
              color: "#FFB5B5",
              // 设置柱形圆角
              borderRadius: 20,
            },
            emphasis: {
              focus: "series",
            },
          },
        ],
      };
      option && myChart.setOption(option, true);
    },
  },
  watch: {
    info: {
      handler(newVal) {
        this.drawBar();
      },
      deep: true,
    },
  },
};
</script>

<style>
</style>